import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Logo from "../../../resources/header/logo.png";

const Header = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(path);
    setSidebarOpen(false); // Close sidebar after navigation
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className="b1-header">
      <div className="b1-logo-title">
        <div className="logo">
          <img
            src={Logo}
            alt="logo"
            style={{ width: "114px", height: "114px" }}
          />
        </div>
        <div className="b1-school-name">
          Sandeepani Hi-Tech School, Bangalore
        </div>
        {/* Hamburger icon for mobile */}
        <div className="hamburger-icon" onClick={toggleSidebar}>
          ☰
        </div>
      </div>

      {/* Navigation Buttons */}
      <div className={`b1-nav-buttons ${sidebarOpen ? "open" : ""}`}>
        <button className="b1-button" onClick={() => navigateTo("/sandeepanihitechschool")}>
          Home
        </button>
        <button className="b1-button" onClick={() => navigateTo("/sandeepanihitechschool/about")}>
          About Us
        </button>
        <button className="b1-button" onClick={() => navigateTo("/sandeepanihitechschool/faculties")}>
          Faculties
        </button>
        <button className="b1-button" onClick={() => navigateTo("/sandeepanihitechschool/admissions")}>
          Admissions
        </button>
        <button className="b1-button" onClick={() => navigateTo("/sandeepanihitechschool/facilities")}>
          Facilities
        </button>
        <button className="b1-button" onClick={() => navigateTo("/sandeepanihitechschool/gallery")}>
          Gallery
        </button>
        <button className="b1-button" onClick={() => navigateTo("/sandeepanihitechschool/news")}>
          News
        </button>
        <button className="b1-button" onClick={() => navigateTo("/sandeepanihitechschool/alumina")}>
          Alumina
        </button>
        <button className="b1-button" onClick={() => navigateTo("/sandeepanihitechschool/contact")}>
          Contact Us
        </button>
        <button className="b1-button" onClick={() => navigateTo("/")}>
          Main Site
        </button>
        <button className="b1-button" onClick={() => navigateTo("/sandeepanihitechschool/login")}>
          Login
        </button>
      </div>

    </div>
  );
};

export default Header;
