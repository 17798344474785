import { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import image2 from "../../../resources/home/branch2.jpeg";
import image11 from "../../../resources/home/chairman.jpg";
import image12 from "../../../resources/home/new img director.jpeg";
import UpArrow from "../../../utilities/UpArrow";

const About = () => {
  const[scroll,setScroll] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };
  if(scroll){
    window.scroll(0, 0);
    setScroll(false)
  }
 
  return (
    <div> 
      <Header />
      <div className="about-container">
        <div className="abt-title1">Welcome to Sandeepani Hi-tech School</div>
        <div className="abt-title2">
          At Saandeepani Hi-tech School, we believe in nurturing young minds
          and fostering holistic development through education. Established with
          a vision to provide excellence in education, we have been a
          cornerstone in the educational landscape for over years. Our
          commitment to academic rigor, character building, and innovation sets
          us apart as a leader in the field of education.
        </div>
        <div className="img2">
          <img
            src={image2}
            alt="img"
          />
        </div>
        <br />
        <div>
          <div className="abt-rectangles">
            <div className="rectangle1">
              <div className="head1">Our Mission</div>
              <div className="section1">
                Our mission is to empower every student to achieve their full
                potential academically, socially, and emotionally. We aim to
                cultivate a love for learning, critical thinking skills, and a
                sense of responsibility towards society.
              </div>
            </div>
            <div className="rectangle2">
              <div className="head2">Academic Excellence</div>
              <div className="section2">
                At Sandeepani Group of Schools, academic excellence is at the
                heart of everything we do. Our curriculum is designed to be
                rigorous yet flexible, ensuring that each student receives a
                well-rounded education. We emphasize not only academic knowledge
                but also practical skills and experiential learning
                opportunities
              </div>
            </div>
            <div className="rectangle1">
              <div className="head1">Holistic Development</div>
              <div className="section1">
                We recognize the importance of holistic development and offer a
                wide range of extracurricular activities including sports, arts,
                music, and community service programs. These activities are
                designed to encourage creativity, teamwork, leadership, and
                personal growth.
              </div>
            </div>
            <div className="rectangle2">
              <div className="head2">Infrastructure and Facilities</div>
              <div className="section2">
                Our state-of-the-art infrastructure supports a conducive
                learning environment. Modern classrooms, well-equipped
                laboratories, libraries stocked with a diverse range of books,
                and recreational areas contribute to a stimulating educational
                experience.
              </div>
            </div>
            <div className="rectangle1">
              <div className="head1">Qualified Faculty</div>
              <div className="section1">
                Our dedicated team of educators are highly qualified,
                passionate, and committed to the success of every student. They
                provide personalized attention, mentorship, and guidance,
                ensuring that each student receives the support they need to
                thrive.
              </div>
            </div>
            <div className="rectangle2">
              <div className="head2">Parental Engagement</div>
              <div className="section2">
                We believe in the importance of a strong partnership between the
                school and parents. Regular communication, parent-teacher
                meetings, and workshops ensure that parents are actively
                involved in their child's educational journey.
              </div>
            </div>
            <div className="rectangle1">
              <div className="head1">Global Perspective</div>
              <div className="section1">
                In an increasingly interconnected world, we prepare our students
                to be global citizens. Through international exchange programs,
                cultural exchanges, and exposure to global issues, we broaden
                their horizons and instill a sense of empathy and understanding.
              </div>
            </div>
            <div className="rectangle2">
              <div className="head2">Community Outreach</div>
              <div className="section2">
                Sandeepani Group of Schools is committed to giving back to the
                community. Our students actively participate in various outreach
                programs and initiatives, promoting social responsibility and
                making a positive impact on society.
              </div>
            </div>

          
          </div>
        </div>
        <div>
          <div className="box1">
            <div className="rec1"></div>
            <div className="home-title2">Chairman's Desk</div>
          </div>
          <div className="sec1-container">
            <div className="img2">
              <img src={image11} alt="img11" />
            </div>
            <div className="home-title1">Chairman</div>
            <div className="home-title2">Lokesh Talikatte</div>
            <div className="description">
              <div className={`hidden-content ${isExpanded ? "show" : ""}`}>
                Students today have an unquenchable thirst to seek knowledge
                that cannot be satiated by traditional methods of imparting
                education like rote learning and passive listening. With the
                paradigm shift in the field of education, children at Sandeepani
                group of institutions will be provided with opportunities and
                situations that facilitate them to exhibit their two-hidden
                potential to the fullest.
              </div>
              <div className={`hidden-content ${isExpanded ? "show" : ""}`}>
                We believe that a good education is one that shapes the growth
                of an individual’s creativity, knowledge, and character. Our
                accredited curriculum is designed to recognize individual
                differences and foster the development of each student’s
                intellect and personality. We strive to meet the needs of the
                whole child and encourage our students to explore, inquire, and
                to think critically.
                <br />
              </div>
            </div>
            <button class="read-more-button" onClick={toggleReadMore}>
                {" "}
                {isExpanded ? "Read Less" : "Read More"}
              </button>
          </div>

          <div className="box1">
            <div className="rec1"></div>
            <div className="home-title2">Director’s Desk</div>
          </div>

          <div className="sec1-container">
            <div className="img2">
              <img src={image12} alt="img12" />
            </div>
            <div className="home-title1">Director</div>
            <div className="home-title2">Roopa</div>
            <div className="description">   
                <div className="hidden-content">
                  As the Director of Sandeepani Group of Schools, it is with
                  immense pleasure and pride that I welcome you to our vibrant
                  educational community. Our institution stands as a beacon of
                  excellence, committed to nurturing young minds and fostering a
                  lifelong love for learning.
                </div>
                
                <div className={`hidden-content ${isExpanded ? "show" : ""}`}>
                  At Sandeepani, we believe in holistic education that goes
                  beyond textbooks and classrooms. Our mission is to cultivate
                  an environment where students can explore their passions,
                  develop critical thinking skills, and grow into responsible
                  global citizens. We are dedicated to providing a balanced
                  curriculum that integrates academic rigor with co-curricular
                  activities, ensuring our students emerge well-rounded and
                  prepared for the challenges of the future.
                  <br/>
                </div>
                <div className={`hidden-content ${isExpanded ? "show" : ""}`}>
                  Our team of dedicated educators is at the heart of this
                  mission. Their unwavering commitment to student success,
                  combined with innovative teaching methodologies, creates a
                  dynamic and engaging learning experience. We continuously
                  strive to stay at the forefront of educational advancements,
                  incorporating the latest technologies and pedagogical
                  practices to enhance our students' learning journey. Parents
                  play a crucial role in the educational process, and we value
                  your partnership and support. Together, we can create a
                  nurturing and motivating atmosphere that encourages our
                  children to aim high and achieve their dreams. We encourage
                  open communication and collaboration, as your insights and
                  feedback are invaluable to us.
                </div>
                <div className={`hidden-content ${isExpanded ? "show" : ""}`}>
                  To our students, I say this: Dream big, work hard, and stay
                  curious. Embrace every opportunity to learn and grow, both
                  inside and outside the classroom. Remember that education is
                  not just about acquiring knowledge but also about building
                  character and developing a sense of responsibility towards
                  society.
                </div>
                <div className={`hidden-content ${isExpanded ? "show" : ""}`}>
                  As we move forward, we remain committed to our vision of
                  excellence and our mission to educate, empower, and inspire. I
                  look forward to another successful year filled with
                  achievements, discoveries, and joyous moments.
                </div>
                <div className={`hidden-content ${isExpanded ? "show" : ""}`}>
                  Thank you for being a part of the Sandeepani family. Together,
                  let us continue to build a brighter future.
                </div>
              
            </div>
            <button class="read-more-button" onClick={toggleReadMore}>
                  {" "}
                  {isExpanded ? "Read Less" : "Read More"}
                </button>
          </div>
        </div>
        <UpArrow />
      </div>
      <Footer />
    </div>
  );
};

export default About;
