import React, { useState } from 'react';
import Header from './Header';
import { Link } from 'react-router-dom';
import axios from 'axios'; // Import axios for API calls

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [identifierError, setIdentifierError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [serverError, setServerError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const validateInput = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobilePattern = /^[0-9]{10}$/;
    let isValid = true;

    // Reset previous errors
    setIdentifierError('');
    setPasswordError('');
    setServerError('');

    if (!emailPattern.test(email) && !mobilePattern.test(email)) {
      setIdentifierError('Please enter a valid email or mobile number');
      isValid = false;
    }

    if (password.length < 6) {
      setPasswordError('Password must be at least 6 characters long');
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateInput()) return;

    // Prepare the payload
    // const loginData = { identifier, password };
    const loginData = { 
      email: email,  
      password: password,      
    };
  
 
    try {
      const response = await axios.post('https://sandeepanischools.in/api/login',  JSON.stringify(loginData), {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      // Handle the response, e.g., if login is successful
      console.log(response);
      if (response.data.success) {
        setSuccessMessage('Login successful!');
      } else {
        setServerError(response.data.message || 'Login failed');
      }
    } catch (error) {
      setServerError('Please check your credentials');
    }
  };

  return (
    <div>
      <Header />
      <div className="login-container">
        <h2 className="login-title">Login</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-container">
            <input
              type="text"
              placeholder="Email or Mobile Number"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="login-input"
              required
            />
            {identifierError && <div className="error">{identifierError}</div>}
          </div>
          <div className="input-container">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="login-input"
              required
            />
            {passwordError && <div className="error">{passwordError}</div>}
          </div>
          <button type="submit" className="login-button">Login</button>
          {serverError && <div className="error">{serverError}</div>}
          {successMessage && <div className="success">{successMessage}</div>}
        </form>
        <Link to="/sandeepanihitechschool/registration">Register?</Link>
      </div>
    </div>
  );
};

export default Login;
