import { useNavigate } from "react-router-dom";
import { useState } from "react";
const Header = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(path);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className="header1">
      <div className="logo-title">
        <div className="logo">
          <img
            src="https://i.ibb.co/k9rpppn/main-logo-removebg-preview.png"
            alt="logo"
            style={{ width: "114px", height: "114px" }}
          />
        </div>
        <div className="school-name">Sandeepani Group of Institutions</div>
        {/* Hamburger icon for mobile */}
        <div className="hamburger-icon" onClick={toggleSidebar}>
          ☰
        </div>
      </div> 
      <div className="nav-container">
        
        <div className={`sidebar ${sidebarOpen ? 'open' : ''}`}>
          <button className="button" onClick={() => navigateTo("/")}>
            Home
          </button>
          <button className="button" onClick={() => navigateTo("/about")}>
            About Us
          </button>
          <button className="button" onClick={() => navigateTo("/gallery")}>
            Gallery
          </button>
          <button className="button" onClick={() => navigateTo("/news")}>
            Notifications
          </button>
          <button className="button" onClick={() => navigateTo("/contact")}>
            Contact Us
          </button>
          <button className="b1-button" onClick={() => navigateTo("/fee")}>
            Fee Payments
          </button>
        </div>
        <div className={`nav-buttons ${sidebarOpen ? 'hidden' : ''}`}>
          <button className="button" onClick={() => navigateTo("/")}>
            Home
          </button>
          <button className="button" onClick={() => navigateTo("/about")}>
            About Us
          </button>
          <button className="button" onClick={() => navigateTo("/gallery")}>
            Gallery
          </button>
          <button className="button" onClick={() => navigateTo("/news")}>
            Notifications
          </button>
          <button className="button" onClick={() => navigateTo("/contact")}>
            Contact Us
          </button>
          <button className="b1-button" onClick={() => navigateTo("/fee")}>
            Fee Payments
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
