import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";

const Gallery = ({galleryImages}) => {
  const [selectedImage, setSelectedImage] = useState(null);



  const openModal = (src) => {
    setSelectedImage(src);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div>
      <Header />
      <div className="gallery-container">
        <div className="grid-container">
          {galleryImages.map((image, index) => (
            <img
              key={index}
              className="grid-item"
              src={image}
              alt="img"
              onClick={() => openModal(image)}
            />
          ))}
        </div>

        {/* Modal for larger image view */}
        {selectedImage && (
          <div className="modal" onClick={closeModal}>
            <img className="modal-image" src={selectedImage} alt="Selected" />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Gallery;
