import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import axios from 'axios';

const Register = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [mobileError, setMobileError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [serverError, setServerError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  
  const validateInput = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobilePattern = /^[0-9]{10}$/;
    let isValid = true;

    // Reset previous errors
    setFirstNameError('');
    setLastNameError('');
    setEmailError('');
    setMobileError('');
    setPasswordError('');
    setConfirmPasswordError('');
    setServerError('');
    setSuccessMessage('');

    if (!firstName) {
      setFirstNameError('First name is required');
      isValid = false;
    }
    if (!lastName) {
      setLastNameError('Last name is required');
      isValid = false;
    }
    if (!emailPattern.test(email)) {
      setEmailError('Please enter a valid email address');
      isValid = false;
    }
    if (!mobilePattern.test(mobile)) {
      setMobileError('Mobile number must be 10 digits long');
      isValid = false;
    }
    if (password.length < 6) {
      setPasswordError('Password must be at least 6 characters long');
      isValid = false;
    }
    if (password !== confirmPassword) {
      setConfirmPasswordError('Passwords do not match');
      isValid = false;
    }

    return isValid;
  };

  const registrationData = { 
    email: email,  
    password: password, 
    firstName:firstName,
    lastName:lastName,
    mobile:mobile   
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateInput()) {
      try {
        const response = await axios.post('https://sandeepanischools.in/api/register',  JSON.stringify(registrationData), {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const data = await response.json();

        if (response.ok) {
          setSuccessMessage('Registration successful! Please log in.');
          console.log('Registration successful! Please log in.');
          setFirstName('');
          setLastName('');
          setEmail('');
          setMobile('');
          setPassword('');
          setConfirmPassword('');
        } else {
          setServerError(data.message || 'Failed to register. Please try again.');
        }
      } catch (error) {
        setServerError('Server error. Please try again later.');
      }
    }
  };

  return (
    <div>
      <Header />
      <div className="register-container">
        <h2 className="register-title">Register</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-container">
            <input
              type="text"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className="register-input"
              required
            />
            {firstNameError && <div className="error">{firstNameError}</div>}
          </div>
          <div className="input-container">
            <input
              type="text"
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className="register-input"
              required
            />
            {lastNameError && <div className="error">{lastNameError}</div>}
          </div>
          <div className="input-container">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="register-input"
              required
            />
            {emailError && <div className="error">{emailError}</div>}
          </div>
          <div className="input-container">
            <input
              type="tel"
              placeholder="Mobile Number"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              className="register-input"
              required
            />
            {mobileError && <div className="error">{mobileError}</div>}
          </div>
          <div className="input-container">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="register-input"
              required
            />
            {passwordError && <div className="error">{passwordError}</div>}
          </div>
          <div className="input-container">
            <input
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="register-input"
              required
            />
            {confirmPasswordError && <div className="error">{confirmPasswordError}</div>}
          </div>
          <button type="submit" className="register-button">Register</button>
          {serverError && <div className="error">{serverError}</div>}
          {successMessage && <div className="success">{successMessage}</div>}
        </form>
        <Link to="/sandeepanihitechschool/login">Login?</Link>
      </div>
    </div>
  );
};

export default Register;
