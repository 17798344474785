// import Header from "./Header";
// import Footer from "./Footer";
import "./payment.css";
// import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState,useEffect } from "react";


const PaymentComponent = ({ details, amount }) => {

  const navigate = useNavigate(); 
  
  // const [razorpayLoaded, setRazorpayLoaded] = useState(false);
  
  // useEffect(() => {
  //   // Dynamically load Razorpay script
  //   const script = document.createElement("script");
  //   script.src = "https://checkout.razorpay.com/v1/checkout.js";
  //   script.onload = () => setRazorpayLoaded(true); // Set state when the script is loaded
  //   document.body.appendChild(script);

  //   // return () => {
  //   //   // Clean up the script when component unmounts
  //   //   document.body.removeChild(script);
  //   // };
  // }, []);

  // const payNow = async () => {
  //   if (!razorpayLoaded) {
  //     console.error("Razorpay script not loaded yet");
  //     return;
  //   }

  //   try {
  //     // Create order on the server
  //     // const response = await axios.post("https://sandeepanischools.in/create-order", {
  //     //   amount: amount,
  //     //   currency: "INR",
  //     //   receipt: "receipt#1",
  //     //   notes: {},
  //     // });
  //     const response = await fetch('/create-order', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify({ amount, currency: 'INR', receipt: 'receipt#1', notes: {} })
  //     });

  //     const order = response.data;

  //     // Configure Razorpay options
  //     const options = {
  //       key: "rzp_test_YKnmnF4Kun2z5B", 
  //       amount: order.amount,
  //       currency: order.currency,
  //       name: "Sandeepani Group of Institution",
  //       description: "Test Transaction",
  //       order_id: order.id,
  //       prefill: {
  //         name: "santhosh",
  //         email: "santhosh7banja@gmail.com",
  //         contact: "6309589838",
  //       },
  //       theme: {
  //         color: "#F37254",
  //       },
  //       handler: function (response) {
  //         // On successful payment
  //         navigate("/success", { state: { paymentId: response.razorpay_payment_id } });
  //       },
  //       modal: {
  //         ondismiss: function () {
  //           // On payment failure or dismissal
  //           navigate("/failure");
  //         },
  //       },
  //     };

  //     const rzp = new window.Razorpay(options);
  //     rzp.open();
  //   } catch (error) {
  //     console.error("Payment failed:", error);
  //     navigate("/failure");
  //   }
  // };
  const [razorpayLoaded, setRazorpayLoaded] = useState(false);

  useEffect(() => {
    // Dynamically load Razorpay script
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.onload = () => setRazorpayLoaded(true); // Set state when the script is loaded
    document.body.appendChild(script);

    // return () => {
    //   // Clean up the script when component unmounts
    //   document.body.removeChild(script);
    // };
  }, []);
  

  const payNow = async () => {
    if (!amount) {
      alert("Please enter a valid amount!");
      return;
    }
    if (!razorpayLoaded) {
      console.error("Razorpay script not loaded yet");
      return;
    }

    const paymentAmount = amount * 100;

    try {
      const response = await fetch("https://sandeepanischools.in/create-order", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amount: paymentAmount,
          currency: "INR",
          receipt: "receipt#1",
          notes: {},
        }),
      });

      const order = await response.json();

      const options = {
        key: "rzp_test_YKnmnF4Kun2z5B", 
        amount: order.amount,
        currency: order.currency,
        name: "Sandeepani Group of Institution",
        description: "Test Transaction",
        order_id: order.id,
        // callback_url: "https://sandeepanischools.in",
        prefill: {
          name: "santhosh",
          email: "santhosh7banja@gmail.com",
          contact: "6309589838", 
        },
        theme: {
          color: "#00008b",
        },
        handler: function (response) {
                  // On successful payment
                  navigate("/success", { state: { paymentId: response.razorpay_payment_id } });
                },
                modal: {
                  ondismiss: function () {
                    // On payment failure or dismissal
                    navigate("/failure");
                  },
                },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error("Error during payment:", error);
      navigate("/failure");
    }
  };

  return (
    <div className="payment-component-container">
      
      <div className="payment-summary">
        <h2 className="payment-summary-heading">Payment Summary</h2>
        <p className="payment-summary-item">
          <strong>Admission Number:</strong> {details.admissionNo}
        </p>
        <p className="payment-summary-item">
          <strong>Class:</strong> {details.class}
        </p>
        <p className="payment-summary-item">
          <strong>Total Amount:</strong> ₹{amount}
        </p>
        <button className="payment-button" onClick={payNow}>
          Pay Now
        </button>
      </div>
    </div>
  );
};

export default PaymentComponent;
