import React, { useState } from "react";
import PaymentComponent from "./PaymentComponent";
import Header from "./Header";
import Footer from "./Footer";
import './studentFee.css';

const classFees = {
  1: 5000,
  2: 5200,
  3: 5400,
  4: 5600,
  5: 5800,
  6: 6000,
  7: 6200,
  8: 6400,
  9: 6600,
  10: 6800,
};

const StudentPayment = () => {
  const [studentDetails, setStudentDetails] = useState({
    admissionNo: "",
    class: "",
  });
  const [amount, setAmount] = useState(null);
  const [showPayment, setShowPayment] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStudentDetails({ ...studentDetails, [name]: value });
  };

  const handleGenerateAmount = () => {
    const fee = classFees[studentDetails.class];
    setAmount(fee);
  };

  const handleProceedToPayment = () => {
    if (amount) {
      setShowPayment(true);
    }
  };

  return (
    <div className="student-payment-container">
      <Header />
      <div className="student-payment-content">
        {!showPayment ? (
          <div className="form-container">
            <h2 className="form-heading">Student Payment</h2>
            <label className="form-label">
              Admission Number:
              <input
                type="text"
                name="admissionNo"
                value={studentDetails.admissionNo}
                onChange={handleChange}
                placeholder="Enter Admission No."
                className="form-input"
              />
            </label>
            <label className="form-label">
              Class:
              <select
                name="class"
                value={studentDetails.class}
                onChange={handleChange}
                className="form-select"
              >
                <option value="">Select Class</option>
                {Object.keys(classFees).map((cls) => (
                  <option key={cls} value={cls}>
                    Class {cls}
                  </option>
                ))}
              </select>
            </label>
            <button className="generate-button" onClick={handleGenerateAmount}>
              Generate Amount
            </button>
            {amount && (
              <div className="amount-container">
                <p className="amount-display">Total Amount: ₹{amount}</p>
                <button
                  className="proceed-button"
                  onClick={handleProceedToPayment}
                >
                  Proceed to Payment
                </button>
              </div>
            )}
          </div>
        ) : (
          <PaymentComponent details={studentDetails} amount={amount} />
        )}
      </div>
      <Footer />
    </div>
  );
};

export default StudentPayment;
